/*
*  全局定义信息
*
*  @author terrfly
*  @site https://www.oasispay.vip
*  @date 2021/5/8 07:18
*/

const errorPageRouteName = 'Error' //错误页面名称定义
const ACardPay = 'ACardPay' //错误页面名称定义
const ACardPayComplete = 'ACardPayComplete' //错误页面名称定义
const NrlyPay = 'NrlyPay' // 尼日利亚 支付页面
const H5plugin = 'H5plugin' // 尼日利亚 支付页面

const saveRollInfo = "saveRollInfo"
const saveOrderNumber = "saveOrderNumber"
const qrCodeInfo = "qrCodeInfo"
const passGuardRouteList = [errorPageRouteName, ACardPay, ACardPayComplete, NrlyPay, H5plugin, saveRollInfo, saveOrderNumber, qrCodeInfo]  // 不进入路由守卫的name

/** 定义支付方式 **/
const payWay = {
    WXPAY: {wayCode: "WX_JSAPI", routeName: "CashierWxpay"},
    ALIPAY: {wayCode: "ALI_JSAPI", routeName: "CashierAlipay"}
}

export default {
    errorPageRouteName: errorPageRouteName,
    ACardPay: ACardPay,
    ACardPayComplete: ACardPayComplete,
    NrlyPay: NrlyPay,
    H5plugin: H5plugin,
    saveRollInfo: saveRollInfo,
    saveOrderNumber: saveOrderNumber,
    qrCodeInfo: qrCodeInfo,
    passGuardRouteList: passGuardRouteList,
    urlTokenName: "jeepayToken", //URL传递的token名称
    payWay: payWay,
    cacheToken: ""
}

